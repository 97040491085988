import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-glendale-arizona.png'
import image0 from "../../images/cities/scale-model-of-wesley-bolin-memorial-plaza-in-glendale-arizona.png"
import image1 from "../../images/cities/scale-model-of-arizona-capitol-museum-in-glendale-arizona.png"
import image2 from "../../images/cities/scale-model-of-downtown-glendale,-az-in-glendale-arizona.png"
import image3 from "../../images/cities/scale-model-of-sueño-park-in-glendale-arizona.png"
import image4 from "../../images/cities/scale-model-of-starlight-park-in-glendale-arizona.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Glendale'
            state='Arizona'
            image={image}
            lat='34.1425078'
            lon='-118.25507500000003'
            attractions={ [{"name": "Wesley Bolin Memorial Plaza", "vicinity": "1700 W Washington St, Phoenix", "types": ["park", "point_of_interest", "establishment"], "lat": 33.4481438, "lng": -112.09454779999999}, {"name": "Arizona Capitol Museum", "vicinity": "1700 W Washington, Phoenix", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.4481117, "lng": -112.09708380000001}, {"name": "Downtown Glendale, AZ", "vicinity": "W Glendale Ave, Glendale", "types": ["point_of_interest", "establishment"], "lat": 33.5385563, "lng": -112.1843369}, {"name": "Sue\u00f1o Park", "vicinity": "4401 W Encanto Blvd, Phoenix", "types": ["park", "point_of_interest", "establishment"], "lat": 33.47144, "lng": -112.15396399999997}, {"name": "Starlight Park", "vicinity": "7810 W Osborn Rd, Phoenix", "types": ["park", "point_of_interest", "establishment"], "lat": 33.4869888, "lng": -112.22957259999998}] }
            attractionImages={ {"Wesley Bolin Memorial Plaza":image0,"Arizona Capitol Museum":image1,"Downtown Glendale, AZ":image2,"Sueño Park":image3,"Starlight Park":image4,} }
       />);
  }
}